<template>
  <div class="pt-30">
    <div class="content-box mb-30">
      <div class="buy_page">
        <div class="buy_page_left box-shadow" v-loading="tradingLoading">
          <div class="buy_page_left_h">
            <h2>{{ $t("fiat_currency.buy.h2") }}</h2>
          </div>
          <div class="buy_page_left_content">
            <div class="buy_page_left_item1">
              <div class="left_item_col">
                <span class="span1">{{ $t("fiat_currency.buy.span1") }}</span>
                <span class="span2">{{ this.route_data.amount }} USDT</span>
              </div>
              <div class="left_item_col">
                <span class="span1">{{ $t("fiat_currency.buy.span2") }}</span>
                <span class="span2">{{ this.route_data.price_sin }} {{ this.route_data.currency }}</span>
              </div>
              <div class="left_item_col">
                <span class="span1">{{ $t("fiat_currency.buy.span3") }}</span>
                <span class="span2">{{ this.route_data.price }} {{ this.route_data.currency }}</span>
              </div>
            </div>
            <div class="buy_page_left_item2">
              <div class="buy_page_left_item2_h">
                <span>{{ $t("fiat_currency.buy.item2_h") }}</span>
              </div>
              <div class="buy_page_left_item2_p">
                <i class="el-icon-warning"></i>
                <p>
                  {{ $t("fiat_currency.buy.item2_p1") }}（<span>{{
                    $store.state.userEmail
                  }}</span>）{{ $t("fiat_currency.buy.item2_p2") }}
                </p>
              </div>
              <div>
                <div class="bank_msg">
                  <div class="bank_msg_item">
                    <span class="msg_h">{{ $t("fiat_currency.buy.msg_h1") }}:</span>
                    <span class="msg_p">
                      <div>{{ detail.bank.bank_name }}</div>
                      <i class="el-icon-copy-document" @click="copyFn(detail.bank.bank_name)"></i>
                    </span>
                  </div>
                  <div class="bank_msg_item">
                    <span class="msg_h">{{ $t("fiat_currency.buy.msg_h2") }}:</span>
                    <span class="msg_p">
                      <div>{{ detail.bank.branch_name }}</div>
                      <i class="el-icon-copy-document" @click="copyFn(detail.bank.branch_name)"></i>
                    </span>
                  </div>
                  <div class="bank_msg_item">
                    <span class="msg_h">{{ $t("fiat_currency.buy.msg_h3") }}:</span>
                    <span class="msg_p">
                      <div>{{ detail.bank.account }}</div>
                      <i class="el-icon-copy-document" @click="copyFn(detail.bank.account)"></i>
                    </span>
                  </div>
                  <div class="bank_msg_item">
                    <span class="msg_h">swift_code:</span>
                    <span class="msg_p">
                      <div>{{ detail.bank.swift_code }}</div>
                      <i class="el-icon-copy-document" @click="copyFn(detail.bank.swift_code)"></i>
                    </span>
                  </div>
                  <div class="bank_msg_item">
                    <span class="msg_h">{{ $t("fiat_currency.buy.msg_h4") }}:</span>
                    <span class="msg_p">
                      <div>{{ detail.bank.code }}</div>
                      <i class="el-icon-copy-document" @click="copyFn(detail.bank.code)"></i>
                    </span>
                  </div>
                </div>

                <!-- <div class="file_str">
                  <div class="buy_page_left_item2_h">
                    <span>{{ $t("fiat_currency.buy.msg_h5") }}</span>
                  </div>
                  <div class="remark">{{ str }}</div>
                </div> -->

                <div class="file_img">
                  <div class="buy_page_left_item2_h">
                    <span>{{ $t("fiat_currency.buy.msg_h6") }}</span>
                  </div>
                  <el-upload action="" accept="image/*" :multiple="false" :show-file-list="false"
                    :before-upload="onUploadImageB" class="avatar-uploader">
                    <div class="voucher-img" v-if="formData.id_image_b">
                      <el-image class="voucher-img-into" style="width: 360px; height: 200px; border-radius: 5px"
                        :src="formData.id_image_b" fit="cover" />
                    </div>
                    <div class="uploader-into" v-else>
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        {{ $t("fiat_currency.buy.upload_text") }}
                      </div>
                      <div class="el-upload__tip" slot="tip">
                        {{ $t("fiat_currency.buy.upload_tip") }}
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <div class="buy_page_left_b">
            <el-button type="success" size="samll" @click="submitFn" :loading="sload">{{
              $t("fiat_currency.buy.submitFn") }}</el-button>
          </div>
        </div>

        <div class="buy_page_right box-shadow">
          <div class="chat_box">
            <div class="dealers">
              <el-image class="dealers_img" :src="aimg" fit="cover"> </el-image>
              <span>{{ $t("fiat_currency.buy.dealers_img") }}</span>
            </div>

            <div class="chat_box_content" id="read" @scroll="scrollEvent">
              <!-- //on-refresh 对应下拉回调  on-infinite 对应上拉回调 -->
              <!-- <scroller :on-refresh="reffor" :on-infinite="inffor" ref="scrfor" :scrollTo="600" style="width:100%;height:100%;padding:10px 20px;box-sizing: border-box;">
                <div v-for="(item, index) in chat_list" :key="index">
                  <div
                    class="chat_box_item chat_box_item_left"
                    v-if="!item.is_user"
                  >
                    <div class="chat_box_item_top">
                      <el-image
                        class="dealers_img"
                        :src="aimg"
                        fit="cover"
                      ></el-image>
                      <span>交易商</span>
                      <span class="chat_time">{{ item.create_time }}</span>
                    </div>
                    <div class="chat_box_item_bottom">
                      <el-image
                        v-if="item.image"
                        class="into_img"
                        style="width: 150px; height: 150px"
                        :src="item.image"
                        :preview-src-list="[item.image]"
                        fit="cover"
                      ></el-image>
                      <p v-if="item.text">{{ item.text }}</p>
                    </div>
                  </div>
                  <div class="chat_box_item chat_box_item_right" v-else>
                    <div class="chat_box_item_top">
                      <span class="chat_time">{{ item.create_time }}</span>
                      <span>{{ email }}</span>
                      <el-image
                        class="dealers_img"
                        :src="aimg"
                        fit="cover"
                      ></el-image>
                    </div>
                    <div class="chat_box_item_bottom">
                      <el-image
                        v-if="item.image"
                        class="into_img"
                        style="width: 150px; height: 150px"
                        :src="item.image"
                        :preview-src-list="[item.image]"
                        fit="cover"
                      ></el-image>
                      <p v-if="item.text">{{ item.text }}</p>
                    </div>
                  </div>
                </div>
                <div style="height: 100px">
                  这里的空div是占坑，解决了高度提不上去的问题
                </div>
              </scroller> -->

              <div id="read_into">
                <div class="list_loading" v-if="flag_has && chat_list.length > 0">
                  <span class="el-icon-loading"></span>
                </div>
                <div class="list_loading" v-if="flag_no && chat_list.length > 0">
                  <span class="flag_no">{{
                    $t("fiat_currency.buy.flag_no")
                  }}</span>
                </div>

                <div v-for="(item, index) in chat_list" :key="index">
                  <div class="chat_box_item chat_box_item_left" v-if="!item.is_user">
                    <div class="chat_box_item_top">
                      <el-image class="dealers_img" :src="aimg" fit="cover"></el-image>
                      <span>{{ $t("fiat_currency.buy.dealers_img") }}</span>
                      <span class="chat_time">{{ item.create_time }}</span>
                    </div>
                    <div class="chat_box_item_bottom">
                      <el-image v-if="item.image" class="into_img" style="width: 150px; height: 150px" :src="item.image"
                        :preview-src-list="[item.image]" fit="cover"></el-image>
                      <p v-if="item.text">{{ item.text }}</p>
                    </div>
                  </div>
                  <div class="chat_box_item chat_box_item_right" v-else>
                    <div class="chat_box_item_top">
                      <span class="chat_time">{{ item.create_time }}</span>
                      <span>{{ email }}</span>
                      <el-image class="dealers_img" :src="aimg" fit="cover"></el-image>
                    </div>
                    <div class="chat_box_item_bottom">
                      <el-image v-if="item.image" class="into_img" style="width: 150px; height: 150px" :src="item.image"
                        :preview-src-list="[item.image]" fit="cover"></el-image>
                      <p v-if="item.text">{{ item.text }}</p>
                    </div>
                  </div>
                </div>

                <!-- 交易商快捷回复 -->
                <!-- <div v-if="chat_list.length <= 0&&strflag">
                  <div class="chat_box_item chat_box_item_left">
                    <div class="chat_box_item_top">
                      <el-image class="dealers_img" :src="aimg" fit="cover"></el-image>
                      <span>{{ $t("fiat_currency.buy.dealers_img") }}</span>
                      <span class="chat_time">{{ currentTime }}</span>
                    </div>
                    <div class="chat_box_item_bottom">
                      <p>{{ detail.bank.reply }}</p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="submit_box">
              <div class="submit_text">
                <el-input type="text" :placeholder="$t('fiat_currency.buy.placeholder')"
                  @keyup.enter.native="submitChat" v-model="textarea" />
              </div>
              <div class="submit_img">
                <el-upload action="" accept="image/*" size="small" :multiple="false" :show-file-list="false"
                  :before-upload="onUploadImageChat" class="">
                  <el-button type="primary" size="small" icon="el-icon-picture"></el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import aimg from "@/assets/fiat/hpic.png";
import XEClipboard from "xe-clipboard";

export default {
  data() {
    return {
      aimg,
      imageUrl: "",
      formData: {
        id_image_b: "",
        id_image_b_fileName: "",
      },
      email: localStorage.getItem("userEmail"),
      textarea: "",
      route_data: {},
      tradingLoading: false,

      detail: {
        bank: {
          bank_name: '',
          branch_name: '',
          account: '',
          swift_code: '',
          code: '',
        }
      },
      str: "",
      sload: false,
      chat_loading: false,
      chat_list: [],

      chat_image: "",
      chat_image_fileName: "",
      acceptor_id: "",
      socket1: null,

      scrollData: {
        page: 1,
        pageSize: 15,
        total: 0,
      },

      flag_has: false,
      flag_no: false,
      isLoadMore: false,

      viewHeight: 0,

      currentTime: '',

      strflag:false,
    };
  },
  watch: {},
  created: function () {
    this.route_data = this.$route.query;
    this.getTraderMarketInfo();
    // this.str = this.getStr();

    this.currentTime = this.getCurrentDate();
  },
  mounted() {
    let that = this;
  },
  beforeDestroy: function () { },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    // 获取时间年月日
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const time = now.toLocaleTimeString();
      return `${year}-${month}-${day} ${time}`;
    },

    //获取触发到顶部距离
    scrollEvent(e) {
      let _this = this;
      let read = document.getElementById("read");
      // console.log(e.target.clientHeight)
      if (read.scrollTop <= 10) {
        // if(this.scrollData.page<this.scrollData.total){
        if (!this.isLoadMore) {
          //此处判断，上锁，防止重复請求
          this.isLoadMore = true;
          this.getChatData(this.acceptor_id, true);
        }
        // }else{
        //   this.flag_no = true
        // }
      }
    },

    // 生成6位随机数
    getStr() {
      let chars = "0123456789";
      let maxPos = chars.length;
      let code = "";
      for (let i = 0; i < 6; i++) {
        code += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return code;
    },

    onUploadImageB: function (file) {
      return this.onUploadImage(file, "id_image_b");
    },
    onUploadImage: function (file, key) {
      let that = this;
      if (file.size > 1024 * 1024 * 5) {
        this.$message({
          message: that.$t('imageLimit'),
          type: "error",
        });
        return false;
      }
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () {
      //   // console.log(reader.result); //获取到base64格式图片
      //   that.formData[key] = reader.result; //用于图片预览
      // };

      let sendData = new FormData();
      if (file.size > 1024 * 1024 * 2) {
        this.$imgCompress.imgCompress(file)
          .then(f => {
            sendData.append("image", f);
            that.$request
              .post("v1/uploadImage", sendData)
              .then(({ data }) => {
                that.formData[key] = data.url;
                that.formData[key + "_fileName"] = data.fileName;
              })
              .catch(() => { });
          })
      } else {
        sendData.append("image", file);
        that.$request
          .post("v1/uploadImage", sendData)
          .then(({ data }) => {
            that.formData[key] = data.url;
            that.formData[key + "_fileName"] = data.fileName;
          })
          .catch(() => { });
      }
    },

    //聊天室图片
    onUploadImageChat: function (file, key) {
      let that = this;
      if (file.size > 1024 * 1024 * 5) {
        this.$message({
          message: that.$t('imageLimit'),
          type: "error",
        });
        return false;
      }
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () {
      //   // console.log(reader.result); //获取到base64格式图片
      //   that.chat_image = reader.result; //用于图片预览
      //   that.sendMsg("image");
      // };

      let sendData = new FormData();
      if (file.size > 1024 * 1024 * 2) {
        this.$imgCompress.imgCompress(file)
          .then(f => {
            sendData.append("image", f);
            that.$request
              .post("v1/uploadImage", sendData)
              .then(({ data }) => {
                that.chat_image = data.url;
                that.chat_image_fileName = data.fileName;
                that.sendMsg("image");
              })
              .catch(() => { });
          })
      } else {
        sendData.append("image", file);
        that.$request
          .post("v1/uploadImage", sendData)
          .then(({ data }) => {
            that.chat_image = data.url;
            that.chat_image_fileName = data.fileName;
            that.sendMsg("image");
          })
          .catch(() => { });
      }


      // sendData.append("image", file);
      // that.$request
      //   .post("v1/uploadImage", sendData)
      //   .then(({ data }) => {
      //     that.chat_image = data.url;
      //     that.chat_image_fileName = data.fileName;
      //     that.sendMsg("image");
      //   })
      //   .catch(() => {});
    },

    //交易商详情
    getTraderMarketInfo: function () {
      this.tradingLoading = true;
      this.$request
        .post("v1/deal/getTraderMarketInfo", {
          id: Number(this.route_data.id),
        })
        .then(({ data }) => {
          this.detail = data;
          this.tradingLoading = false;
          this.acceptor_id = data.acceptor_id;
          this.getChatData(data.acceptor_id, false);
          this.get_wss();
        });
    },

    //交易商聊天信息
    getChatData: function (id, $flag) {
      let that = this;
      this.strflag = false;
      if (!$flag) {
        this.chat_list = [];
        this.scrollData.page = 1;
      } else {
        this.flag_has = true;
        this.scrollData.page = this.scrollData.page + 1;
      }

      this.chat_loading = true;
      this.$request
        .post("v1/deal/getChatData", {
          acceptor_id: Number(id),
          page: this.scrollData.page,
          pageSize: this.scrollData.pageSize,
          user_id: this.$store.state.userId,
        })
        .then(({ data }) => {
          let arr = data.list;
          this.flag_has = false;
          this.strflag = true;
          // <!-- <div v-if="chat_list.length <= 0&&strflag">
          //         <div class="chat_box_item chat_box_item_left">
          //           <div class="chat_box_item_top">
          //             <el-image class="dealers_img" :src="aimg" fit="cover"></el-image>
          //             <span>{{ $t("fiat_currency.buy.dealers_img") }}</span>
          //             <span class="chat_time">{{ currentTime }}</span>
          //           </div>
          //           <div class="chat_box_item_bottom">
          //             <p>{{ detail.bank.reply }}</p>
          //           </div>
          //         </div>
          //       </div> -->

          // <div class="chat_box_item chat_box_item_left" v-if="!item.is_user">
          //           <div class="chat_box_item_top">
          //             <el-image class="dealers_img" :src="aimg" fit="cover"></el-image>
          //             <span>{{ $t("fiat_currency.buy.dealers_img") }}</span>
          //             <span class="chat_time">{{ item.create_time }}</span>
          //           </div>
          //           <div class="chat_box_item_bottom">
          //             <el-image v-if="item.image" class="into_img" style="width: 150px; height: 150px" :src="item.image"
          //               :preview-src-list="[item.image]" fit="cover"></el-image>
          //             <p v-if="item.text">{{ item.text }}</p>
          //           </div>
          //         </div>

          // 添加快捷回复
          let $json = {
            is_user:0,
            create_time:that.create_time,
            image:'',
            text:that.detail.bank.reply
          }
          arr.unshift($json)
          if (arr.length > 0) {
            if ($flag) {
              let $obj = data;
              arr.forEach(function (item, index) {
                that.chat_list.unshift(item);
              });

              // this.loadingState = 0;
              if (arr.length < this.scrollData.pageSize) {
                //判断接口返回数据量小于請求数据量，则表示此为最后一页
                this.isLoadMore = true;
                this.flag_no = true;
                setTimeout(() => {
                  var div = document.getElementById("read");
                  div.scrollTop = div.scrollHeight - this.viewHeight
                  this.viewHeight = data.height;
                }, 100);
              } else {
                this.isLoadMore = false;
                setTimeout(() => {
                  var div = document.getElementById("read");
                  div.scrollTop = div.scrollHeight - this.viewHeight
                  this.viewHeight = data.height;
                  // div.scrollTop = 20;
                }, 100);
              }
            } else {
              this.scrollData.total = data.total;
              this.chat_list = arr.reverse(); //反转数组

              setTimeout(() => {
                var div = document.getElementById("read");
                div.scrollTop = div.scrollHeight;
                this.viewHeight = div.scrollHeight;
              }, 100);

              if (this.chat_list.length < this.scrollData.pageSize) {
                //判断接口返回数据量小于請求数据量，则表示此为最后一页
                this.isLoadMore = true;
                this.flag_no = true;
              }
            }
          } else {
            this.isLoadMore = true;
            this.flag_no = true;
          }

          // if($flag){
          //   if(arr.length>0){
          //     this.flag_has = false
          //     arr.forEach(function(item,index){
          //       that.chat_list.unshift(item)
          //     })
          //     this.isLoadMore = false;
          //   }else{
          //     this.isLoadMore = true;
          //   }
          // }else{
          //   if(arr.length>0){
          //     this.chat_list = arr.reverse();//反转数组
          //     var div = document.getElementById('read');
          //     div.scrollTop = div.scrollHeight;
          //   }else{
          //     this.chat_list = []
          //   }
          // }

          this.chat_loading = false;
        });
    },

    get_wss: function () {
      if (this.socket1) {
        this.socket1.close(); //离开路由之后断开websocket连接
      }
      //wss實時請求
      // this.socket1 = this.$io("ws://47.243.105.117:9502/chat", {
      this.socket1 = this.$io(this.$wsn + "/chat", {
        query: {},
        transports: ["websocket", "polling"],
        timeout: 5000,
        // pingTimeout: 20000,
        // pingInterval: 25000,
      });

      this.socket1.on("connect_error", (reason) => {
        console.log(reason);
      });

      this.socket1.on("connect", (socket) => {
        // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
        console.log("ws 已连接");

        //加入聊天室
        this.socket1.emit(
          "add_join",
          {
            token: this.$store.state.userToken,
            acceptor_id: this.acceptor_id,
          },
          (res) => {
            // console.log(res);
          }
        );
        // this.socket1.on("add_join", (res) => {
        //   console.log(JSON.parse(res))
        // });
      });

      this.socket1.on("send_text", (res) => {
        if (res) {
          this.chat_list.push(JSON.parse(res));
        }
      });

      this.socket1.on("disconnect", (reason) => {
        console.log(reason);
      });

      this.socket1.on("error", (msg) => {
        console.log("ws error", msg);
      });
    },
    curentTime() {
      var now = new Date();

      var year = now.getFullYear(); //年
      var month = now.getMonth() + 1; //月
      var day = now.getDate(); //日

      var hh = now.getHours(); //时
      var mm = now.getMinutes(); //分
      var ss = now.getSeconds(); //分

      var clock = year + "-";

      if (month < 10) clock += "0";

      clock += month + "-";

      if (day < 10) clock += "0";

      clock += day + " ";

      if (hh < 10) clock += "0";

      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";

      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },

    //提交聊天信息
    sendMsg(e) {
      if (!this.textarea && !this.chat_image) {
        return this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: this.$t("fiat_currency.buy.message1"),
          onClose: function () { },
        });
      }
      if (this.socket1) {
        let $data = {
          token: this.$store.state.userToken,
          acceptor_id: this.acceptor_id,
          data: {},
        };
        if (e == "text") {
          $data.data.text = this.textarea;
          this.sendMsgSubmit($data);
        } else {
          // this.$request
          //   .post("v1/uploadImage", {
          //     image: this.chat_image,
          //   })
          //   .then(({ data }) => {
          //     $data.data.image = data.url;
          //     this.sendMsgSubmit($data);
          //   });
          $data.data.image = this.chat_image_fileName;
          this.sendMsgSubmit($data);
        }
      }
    },

    sendMsgSubmit($data) {
      this.socket1.emit("send_text", $data, (res) => {
        if (JSON.parse(res).code == 200) {
          this.chat_list.push({
            text: this.textarea,
            image: this.chat_image,
            is_user: 1,
            create_time: this.curentTime(),
          });
          this.textarea = "";
          this.chat_image = "";
          this.chat_image_fileName = "";
        } else {
          this.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: this.$t("fiat_currency.buy.message2"),
            onClose: function () { },
          });
        }
      });
    },

    //提交聊天
    submitChat(e) {
      // console.log(555)
      this.sendMsg("text");
    },

    //复制
    copyFn: function (e) {
      if (XEClipboard.copy(e)) {
        this.$message({
          message: this.$t("copied"),
          type: "success",
        });
      }
    },

    //提交
    submitFn: function () {
      this.sload = true;
      this.$request
        .post("v1/deal/buy", {
          id: Number(this.route_data.id),
          money: Number(this.route_data.amount),
          // remark: this.str,
          image: this.formData.id_image_b_fileName,
        })
        .then((data) => {
          let that = this;
          this.$message({
            showClose: true,
            type: "success",
            duration: 2000,
            message: data.message,
            onClose: function () {
              that.$router.push("../user/account/fiat");
            },
          });
          this.sload = false;
        })
        .catch(() => {
          this.sload = false;
        });
    },
  },
};
</script>
<style scoped>
.index-top {
  /* background-image: url(~@/assets/index/top-bg.png); */
  height: 300px;
  background-repeat: no-repeat;
  background-size: auto 460px;
  background-position: center;
}

.icon-style-yuan {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;

  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }

  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}

.market-body {
  .change {
    font-size: 14px !important;
    width: 80px;
    text-align: center !important;
  }
}

.services {
  .item {
    img {
      width: 100px;
      height: 100px;
    }

    p {
      font-size: 14px;
    }
  }
}

// 自选交易列表
.dealers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dealers_img {
  width: 30px;
  height: 30px;
}

.dealers span {
  padding: 0 10px;
  box-sizing: border-box;
}

.bank_card {
  display: inline-block;
  // align-items: center;
  // justify-content: flex-start;
  padding: 1px 7px;
  border: 1px solid #666;
  border-radius: 3px;
  // vertical-align: middle;
  color: #666;
}

.bank_card i {
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}

.bank_card span {
  font-size: 12px;
  line-height: 20px;
  margin-left: 3px;
  vertical-align: middle;
}

.price_style {
  font-weight: bold;
  color: #f9a825;
}

.pagination-outline {
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

// 弹窗样式
.buy_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.buy_content_left {
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}

.buy_content_right {
  width: 50%;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}

.buy_content_right_item1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.right_item_col:nth-child(1) {
  width: 25%;
}

.right_item_col:nth-child(2) {
  width: 55%;
}

.right_item_col:nth-child(3) {
  width: 20%;
  text-align: right;
}

.right_item_col:nth-child(3) .span2 {
  color: #f9a825;
  font-weight: bold;
}

.right_item_col span {
  display: block;
  font-size: 12px;
}

.right_item_col .span1 {
  margin-bottom: 5px;
}

.buy_content_mes {
  font-size: 12px;
}

.buy_content_right_item2_top {
  position: relative;
  margin-bottom: 5px;
}

.buy_content_right_item2_top input {
  width: 100%;
  height: 36px;
  border: 1px solid #f9a825;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 150px 0 10px;
}

.inp-pos {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  right: 10px;
  top: 8px;
}

.inp-pos span {
  line-height: 20px;
}

.inp-pos span:nth-child(2) {
  margin: 0 5px;
  width: 1px;
  height: 12px;
  display: block;
  background: #666;
}

.inp-pos span:nth-child(3) {
  color: #f9a825;
  cursor: pointer;
}

.buy_content_right_item2 {
  margin-bottom: 20px;
}

.buy_content_right_item2_top input::placeholder {
  font-size: 12px;
  font-weight: normal;
  font-family: Arial;
  // letter-spacing: 5px;
  // color: red !important;
  // text-align: center;
}

.buy_content_right_item2_btm {
  font-size: 12px;
  text-align: right;
}

.buy_content_right_item2_btm span {
  margin-right: 5px;
}

.buy_content_right_item3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

//
.buy_page_left {
  width: 60%;
  //   border: 1px solid #e3e6e9 !important;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}

.buy_page_left_h {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(242, 246, 250, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 20px;
  box-sizing: border-box;
}

.buy_page_left_h h2 {
  font-size: 18px;
}

.order_msg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}

.order_msg_item {
  margin-left: 20px;
}

.buy_page_left_content {
  padding: 20px;
  box-sizing: border-box;
}

.buy_page_left_item1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.left_item_col {
  width: 33.3%;
}

.left_item_col span {
  display: block;
  font-size: 14px;
}

.left_item_col .span1 {
  margin-bottom: 10px;
  color: #999;
}

.left_item_col:nth-child(3) .span2 {
  color: #f9a825;
  font-weight: bold;
}

.buy_page_left_item1 {
  margin-bottom: 30px;
}

.buy_page_left_item2_h {
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}

.buy_page_left_item2_p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  background: rgba(255, 251, 243, 1);
  border: 1px solid rgb(353, 232, 191);
  box-sizing: border-box;
  margin-bottom: 20px;
}

.buy_page_left_item2_p i {
  color: #e6a23c;
  display: block;
  margin-right: 10px;
}

.buy_page_left_item2_p p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}

.bank_msg {
  padding: 10px 20px;
  background: #f9f9f9;
  margin-bottom: 20px;
}

.bank_msg_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 0;
  box-sizing: border-box;
}

.bank_msg_item i {
  font-size: 22px;
  display: block;
  cursor: pointer;
  transition: all ease 0.3s;
}

.bank_msg_item i:hover {
  color: #f9a825;
}

.msg_p {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.msg_p div {
  padding: 0 10px;
  box-sizing: border-box;
}

.msg_h {
  word-break: keep-all;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  transition: all ease 0.3s;
  width: 360px;
  height: 200px;
  cursor: pointer;
}

.avatar-uploader:hover {
  border: 1px dashed #f9a825;
}

.uploader-into {
  width: 358px;
  height: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploader-into .el-upload__text {
  font-size: 14px;
  color: #f9a825;
}

.uploader-into i {
  font-size: 50px;
  color: #f9a825;
  margin-bottom: 20px;
}

.avatar-uploader .voucher-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy_page_left_b {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background: #f2f6fa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.buy_page_left_b span {
  font-size: 14px;
  color: #67c23a;
  margin-top: 10px;
  cursor: pointer;
}

.buy_page {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.buy_page_right {
  width: 35%;
}

.buy_page_right .dealers {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.submit_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.submit_text {
  width: calc(100% - 50px);
}

.chat_box_content {
  height: 660px;
  overflow: auto;
  // position: relative;
  background: #fff;
  border-top: 1px solid #e3e6e9 !important;
  border-bottom: 1px solid #e3e6e9 !important;
  padding: 20px;
  box-sizing: border-box;
}

.buy_page_right {
  border-radius: 5px;
}

.chat_box_item_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.chat_box_item_top span {
  margin: 0 10px;
}

.chat_box_item_left {
  text-align: left;
}

.chat_box_item_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
}

.chat_box_item_right .chat_box_item_top {
  justify-content: flex-end;
}

.chat_box_item_bottom {
  max-width: 70%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e3e6e9;
}

.chat_box_item {
  margin-bottom: 20px;
}

.chat_box_item_bottom p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.chat_box_item_right .chat_box_item_bottom {
  background: rgb(158, 234, 106);
  border: 1px solid rgb(158, 234, 106);
}

.file_str {
  margin-bottom: 20px;
}

.remark {
  font-size: 26px;
  line-height: 50px;
  color: red;
  font-weight: bold;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px dashed #d9d9d9;
  display: inline-block;
  border-radius: 5px;
}

.chat_time {
  color: #999;
  font-size: 12px;
}

.chat_box_item_bottom {
  display: inline-block;
}

.list_loading {
  padding: 10px 0;
  text-align: center;
}

.flag_no {
  color: #999;
  font-size: 12px;
}
</style>